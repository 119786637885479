/*----------------------------------------------------------------
HOME------------------------------------------------------------*/

#hero {
    height: 100vh;
    width: 100vw;
    background-image: url('./main-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
}
.row {
    height: 100%;
    display: flex;
    align-items: center;
}
.column {
    padding-left: 20vw;
}
@media (max-width: 768px) {
    .column {
        padding: 5%;
    }
    .row {
        justify-content: center;
    }
}


.hero--heading {
    font-family: "Gothic A1", sans-serif;
    font-style: normal;
    font-weight: 400;   
    font-size: 4rem;
    position: relative;
    margin-bottom: 0.7em;
}

.hero--heading::before {
    position: absolute;
    content: '';
    display: block;
    height: 2px;
    width: 1em;
    top: 0.53em;
    left: -1.5em;
    background-color: #f9861a;
}
@media (max-width: 570px) {
    .hero--heading::before {
        display: none;
    }
}

@media (max-width: 430px) {
    .hero--heading::before {
        display: none;
    }
    .hero--heading {
        font-size: 3rem;
    }
}


.hero--examples h4,
.heading--link {
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    color: #f9861a;
    font-size: 2rem;
    letter-spacing: 0.1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
}

.heading--link {
    display: block;
    margin-bottom: 15px;
    transition: all ease 0.2s;
}



.hero--examples ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    
}

.hero--examples li {
    margin-right: 0.7em;
    position: relative;
}

.hero--examples li:nth-child(1)::after,
.hero--examples li:nth-child(2)::after {
    content: '/';
    color: rgba(255, 255, 255, 0.2);
    margin-left: 0.5em;
} 

.app--link {
    font-family: "Work Sans", sans-serif;
    text-decoration: none;
    background: none;
    border: none;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: .3em;
    transition: all ease-in 0.2s;
}


.home--mid--heading {
    margin-top: 15px;
}

.hero--examples .app--link:hover {
    cursor: pointer;
    color: #f9861a;
}


/*----------------------------------------------------------------
HOME-END--------------------------------------------------------*/