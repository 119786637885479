@import url("https://fonts.googleapis.com/css?family=Gothic+A1:300,300i,400,400i,500,500i,600,600i,700,700i|Martel:300,300i,400,400i,700,700i,900,900i|Work+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,700&display=swap");
@import url('./components/pages/home/home.css');
@import url('./components/pages/enlearn/enlearn.css');
@import url('./components/pages/todolist/todos.css');
@import url('./components/pages/tictactoe/tictactoe.css');


@media (min-width: 220px) {
  html {
      font-size: 0.65em;
  }
}

@media (min-width: 501px) {
  html {
      font-size: 0.7em;
  }
}

@media (min-width: 769px) {
  html {
      font-size: 0.8em;
  } 
}

@media (min-width: 1281px) {
  html {
      font-size: 0.85em;
  } 
}

@media (min-width: 1601px) {
  html {
      font-size: 1em;
  } 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

body {
  background: #f5f5f5;
}

a:focus,
a:visited {
  outline: none;
  box-shadow: none;
}